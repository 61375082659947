// Copyright 2017-2022 @polkadot/types-codec authors & contributors
// SPDX-License-Identifier: Apache-2.0
import { u32 } from "./U32.js";
/**
 * @name USize
 * @description
 * A System default unsigned number, typically used in RPC to report non-consensus
 * data. It is a wrapper for [[U32]] as a WASM default (as generated by Rust bindings).
 * It is not to be used, since it created consensus mismatches.
 */

export class usize extends u32 {
  constructor(registry, value) {
    super(registry, value);
    throw new Error('The `usize` type should not be used. Since it is platform-specific, it creates incompatibilities between native (generally u64) and WASM (always u32) code. Use one of the `u32` or `u64` types explicitly.');
  }

}